<template>
<div class="news dirr">
   <v-container>
        <div class="news_con">
            <div class="impoNews" v-if="lastNews_skeleton">
                <v-layout row>
                    <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;">
                        <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                        <v-skeleton-loader  width="800" min-width="800" type="card"></v-skeleton-loader>
                    </v-flex>
                    <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;">
                        <v-skeleton-loader  class="mx-auto" width="350" min-width="350" max-width="350" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences, actions"></v-skeleton-loader>
                    </v-flex>
                </v-layout>
            </div>
            <div class="impoNews" v-else>
                <div v-if="$i18n.locale == 'ar'" style="font-size: 34px; font-weight: bold; justify-content: center; text-align: center;">
                    <div style="display: inline-block;">
                        <!-- <div class="px-8 pb-1">المركز الخبري</div> -->
                        <div class="tl pt-2" style="font-size: 24px;">وكالة أنباء العتبة العباسية المقدسة</div>
                        <hr>
                        
                        <a href="mailto: news@alkafeel.net" style="text-decoration: none; font-size: 15px;color: darkgrey;"> <div class="tl">news@alkafeel.net</div></a>
                    </div>
                    
                </div>
                <div class="title" style="padding:0 20px 20px 20px;">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <router-link :to="'/newscat/all'+'?lang='+$i18n.locale" class="tl"> {{ $t("news_latest") }}</router-link>
                </div>
                <div class="items">
                    <div  class="item Fitem">
                    <v-layout row>
                        <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;">
                        <router-link :to="'/new/'+latestNews[0]  .id+'?lang='+$i18n.locale" >
                            <!-- <img :src="'https://alkafeel.net/news/mcroped96/625/' + latestNews[0]  .image" :alt="latestNews[0]  .title" /> -->
                            <div class="img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+latestNews[0]  .image"
                                    :src="'https://alkafeel.net/news/mcroped96/800/'+latestNews[0]  .image" style="border-radius: 4px;">
                                </v-img>
                                <div class="icon" v-if="latestNews[0]  .video">
                                    <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                    </svg>
                                </div>
                                
                            </div>
                        </router-link>
                        </v-flex>
                        <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;">
                            <div :class="'paregs ' + $store.state.direction">
                            <router-link :to="'/new/'+latestNews[0].id+'?lang='+$i18n.locale"  style="    text-decoration: none;">
                                <p class="tl" v-html="latestNews[0].title"></p>
                                
                                <p v-if="latestNews[0].text" :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="removebbcode(latestNews[0].text) + ' ...'"></p>
                            </router-link>
                                <div class="social d-none d-sm-flex">
                                    <a :href="'https://telegram.me/share/?url=https://alkafeel.net/news/index?id='+latestNews[0].id+'&lang='+$i18n.locale" target="_blank" class="telegram">
                                        <img :src="$vuetify.theme.dark ? '/assets/img/news/Telegram_white.svg' : '/assets/img/news/Telegram_dark.svg'" />
                                    </a>
                                    <a :href="'https://twitter.com/intent/tweet?text=https://alkafeel.net/news/index?id='+latestNews[0].id+'&lang='+$i18n.locale" target="_blank" class="twitter">
                                        <img :src="$vuetify.theme.dark ? '/assets/img/news/twitter_white.svg' : '/assets/img/news/twitter_dark.svg'" />
                                    </a>
                                    <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://alkafeel.net/news/index?id='+latestNews[0].id+'&lang='+$i18n.locale" target="_blank" class="facebook">
                                        <img :src="$vuetify.theme.dark ? '/assets/img/news/Facebook_white.svg' : '/assets/img/news/Facebook_dark.svg'" />
                                    </a>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                    </div>
                    <div class="sitems">
                        <v-layout row>
                            <v-flex xs12 sm6 md4 lg4  v-for="i in latestNews.slice(1)" :key="i" style="padding:8px 10px;">
                                <router-link :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Oitem">
                                    <div class="img">
                                        <img :src="'https://alkafeel.net/news/mcroped96/390/' + i.image" :alt="i.title" />
                                        <div class="icon" v-if="i.video">
                                            <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                            <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div class="home_main_new_cat" style="bottom: 34px !important;">{{TimeSince(i.time)}}</div>
                                    </div>
                                    <p class="tl" v-html="$options.filters.subStrimpoNewsTitle(i.title)"></p>
                                </router-link>
                            </v-flex>
                        </v-layout>
                        <router-link :to="'/newscat/all'+'?lang='+$i18n.locale+'&more=1'" class="tl">
                            <v-btn  height="40" class="ma-2 tl" style="align-self:center;" :loading="loading" color="rgba(177, 189, 82, 1)">
                                <p class="alistSub tl">{{ $t('view_more') }}</p>
                            </v-btn>
                        </router-link>
                    </div>
                    <!-- <div class="slider">
                        <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions">
                            <swiper-slide v-for="i in latestNews.slice(1)" :key="i">
                                <router-link :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Oitem">
                                    <div class="img">
                                        <img :src="'https://alkafeel.net/news/mcroped96/390/' + i.image" :alt="i.title" />
                                        <div class="icon" v-if="i.video">
                                            <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                            <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div class="home_main_new_cat" style="bottom: 34px !important;">{{TimeSince(i.time)}}</div>
                                    </div>
                                    <p class="tl">{{i.title | subStrimpoNewsTitle}}</p>
                                </router-link>
                            </swiper-slide>
                            <div class="swiper-pagination swiper-pagination-bullets d-none d-sm-none d-md-flex" slot="pagination"></div>
                        </swiper>
                    </div> -->
                </div>
            </div>
            <div class="lastNews" v-if="impoNews_skeleton">
                <v-layout row>
                    <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;">
                        <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                        <v-skeleton-loader  width="800" min-width="800" type="card"></v-skeleton-loader>
                    </v-flex>
                    <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;">
                        <v-skeleton-loader  class="mx-auto" width="350" min-width="350" max-width="350" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences, actions"></v-skeleton-loader>
                    </v-flex>
                </v-layout>
            </div>
            <div class="lastNews" v-else>
                <div class="title" style="padding:0 20px 20px 20px;">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <!-- <router-link :to="'/newscat/all'+'?lang='+$i18n.locale" class="tl"> {{ $t("news_sticky") }}</router-link> -->
                    <p class="tl"> {{ $t("news_sticky") }}</p>
                </div>
                <div class="items">
                    <v-layout row class="d-none d-sm-flex">
                        <v-flex sm12 xs12 md6 lg5 style="padding:0 10px;">
                            <router-link :to="'/new/'+impoNews[0].id+'?lang='+$i18n.locale" class="item Fitem">
                                <!-- <img :src="'https://alkafeel.net/news/mcroped96/625/' + latestNews[0]  .image" :alt="latestNews[0]  .title" /> -->
                                <div class="img">
                                    <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+impoNews[0]  .image"
                                        :src="'https://alkafeel.net/news/mcroped96/625/'+impoNews[0]  .image" style="height: 100%; width: 100%; border-radius: 4px;">
                                    </v-img>
                                    <div class="icon" v-if="impoNews[0]  .video">
                                        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                        <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                        </svg>
                                    </div>

                                    <div class="home_main_new_cat" style="bottom: 34px !important;">

                                    <!-- {{moment.unix(item.time).locale($i18n.locale).fromNow()}} -->
                                        {{TimeSince(impoNews[0].time)}}

                                    </div>
                                </div>
                                <p class="tl" v-html="impoNews[0].title"></p>
                                <p v-if="impoNews[0].text" :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="removebbcode(impoNews[0].text) + ' ...'"></p>
                            </router-link >
                        </v-flex>
                        <v-flex sm12 xs12 md6 lg7 style="padding:0 10px;">
                            <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" v-for="i in impoNews.slice(1)" :key="i" class="item Oitem" >
                                <div :class="'paregs ' + $store.state.direction">
                                    <p class="tl" v-html="i.title"></p>
                                    <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="vhtmlText(i.text)"></p>
                                </div>
                                <div class="img">
                                    <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                        :src="'https://alkafeel.net/news/mcroped96/260/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                                    </v-img>
                                    <div class="icon" v-if="i.video">
                                        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                        <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                        </svg>
                                    </div>

                                    <div class="home_main_new_cat" style="bottom: 34px !important;">

                                    <!-- {{moment.unix(item.time).locale($i18n.locale).fromNow()}} -->
                                        {{TimeSince(i.time)}}

                                    </div>
                                    
                                </div>
                            </router-link>
                        </v-flex>
                    </v-layout>
                    <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" v-for="i in impoNews" :key="i" class="item Oitem d-flex d-sm-none" style="margin:20px 10px;">
                        <div :class="'paregs ' + $store.state.direction">
                            <p class="tl" v-html="i.title"></p>
                            <p class="tx d-none d-sm-flex" v-html="vhtmlText(i.text)"></p>
                        </div>
                        <!-- <img :src="'https://alkafeel.net/news/mcroped96/625/' + i.image" :alt="i.title" /> -->
                        <div class="img">
                            <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                            </v-img>
                        </div>
                    </router-link>
                </div>
            </div>





            <div class="lastNews" v-if="reportNews.length > 0">
            <!-- <div class="title" style="padding:0 20px 20px 20px;">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <router-link :to="'/new/reports'+'?lang='+$i18n.locale" class="tl">{{ $t("news_reports") }}</router-link>
            </div>
            <div class="items">
                <v-layout row class="d-none d-sm-flex">
                    <v-flex sm12 xs12 md6 lg5 style="padding:0 10px;">
                        <router-link :to="'/new/'+reportNews[0].id+'?lang='+$i18n.locale" class="item Fitem">
          
                            <div class="img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+reportNews[0]  .image"
                                    :src="'https://alkafeel.net/news/mcroped96/625/'+reportNews[0]  .image" style="height: 100%; width: 100%; border-radius: 4px;">
                                </v-img>
                                <div class="icon" v-if="reportNews[0].video">
                                    <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                    </svg>
                                </div>

                                <div class="home_main_new_cat" style="bottom: 34px !important;">

                                    {{TimeSince(reportNews[0].time)}}

                                </div>
                            </div>
                            <p class="tl" v-html="reportNews[0].title"></p>
                            <p v-if="reportNews[0].text" :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="removebbcode(reportNews[0].text) + ' ...'"></p>
                        </router-link >
                    </v-flex>
                    <v-flex sm12 xs12 md6 lg7 style="padding:0 10px;">
                        <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" v-for="i in reportNews.slice(1)" :key="i" class="item Oitem" >
                            <div :class="'paregs ' + $store.state.direction">
                                <p class="tl" v-html="i.title"></p>
                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="vhtmlText(i.text)"></p>
                            </div>
                            <div class="img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                    :src="'https://alkafeel.net/news/mcroped96/260/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                                </v-img>
                                <div class="icon" v-if="i.video">
                                    <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                    </svg>
                                </div>

                                <div class="home_main_new_cat" style="bottom: 34px !important;">
                                    {{TimeSince(i.time)}}
                                </div>
                                
                            </div>
                        </router-link>
                    </v-flex>
                </v-layout>
                <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" v-for="i in reportNews" :key="i" class="item Oitem d-flex d-sm-none" style="margin:20px 10px;">
                    <div :class="'paregs ' + $store.state.direction">
                        <p class="tl" v-html="i.title"></p>
                        <p class="tx d-none d-sm-flex" v-html="vhtmlText(i.text)"></p>
                    </div>
                    
                    <div class="img">
                        <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                            :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                        </v-img>
                    </div>
                </router-link>
            </div> -->
        </div>







            <div class="picNews zx" v-if="$i18n.locale === 'ar'">
                <div class="title">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <router-link :to="'/new/photo'+'?lang='+$i18n.locale" class="tl">{{ $t("Picture_Reports") }}</router-link>
                </div>
                <div class="items">
                    <v-container class="infograph">
                        <v-layout row class="inf m-2">
                            <v-col cols="12" md="6" lg="4" style="margin-bottom: 50px;" v-for="(card, index) in picNews" :key="index">
                                <transition name="fade">

                                    <div class="video-wrapper" v-if="card.video_file">
                                        <router-link  :to="'/new/infograph_inner/'+ card.id + '?&lang=' +$i18n.locale">
                                            <video @mouseenter="playVideo" @mouseleave="pauseVideo" @touchstart="playVideo" @touchend="pauseVideo" muted :poster="'https://alkafeel.net/news/images/main/370/' + card.image" :src="'https://alkafeel.net/news/images/infograph/'+card.video_file" class="image-hover-scale full-height-img video-container" ></video>
                                        </router-link>
                                        <button v-if="!isPlaying" class="play-btn" @click="togglePlay">
                                            <span class="play-icon">{{ isPlaying ? '❚❚' : '▶' }}</span>
                                        </button>
                                    </div>
                                        
                                    <v-carousel :cycle="index === 0" interval="3000" :show-arrows="false">
                                        <v-carousel-item v-for="(image, i) in [card.image, ...card.images]" :key="i">
                                            <router-link :to="'/new/infograph_inner/'+ card.id + '?&lang=' +$i18n.locale" class="inf_item">
                                                <img :src="image.includes('http') ? image : 'https://alkafeel.net/news/images/main/370/' + image" class="image-hover-scale full-height-img">
                                            </router-link>
                                        </v-carousel-item>
                                    </v-carousel>
                                </transition>
                            </v-col>
                        </v-layout>
                    </v-container>
                </div>
            </div>

            
        </div>
        <div class="videoNews mt-6" >
            <div class="title" style="padding:0 20px 20px 20px;">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <router-link :to="'/new/video'+'?lang='+$i18n.locale" class="tl">{{ $t("Video_Reports") }}</router-link>
            </div>
            <div class="items">
                <div class="slider" style="width: 100%;">
                    <div :class="'sbtn lbtn d-none d-lg-flex d-xl-flex '+ $store.state.direction"></div>
                    <swiper class="swiper mx-h dirr" ref="mySwiper" :options="swiperOptions3">
                        <swiper-slide v-for="i in videoNews" :key="i">
                        <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                            <div class="img_con">
                                <div class="play">
                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="32" cy="32" r="32" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                </svg>
                                </div>
                                <img :src="'https://alkafeel.net/news/mcroped96/330/' + i.image" :alt="i.title"/>
                                <!-- <div class="img">
                                    <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+latestNews[0]  .image"
                                        :src="'https://alkafeel.net/news/mcroped96/330/'+latestNews[0]  .image" style="border-radius: 4px;">
                                    </v-img>
                                </div> -->
                            </div>
                            <p class="tl" style="min-height:55px;" v-html="$options.filters.subStrvideoNewsTitle(i.title)"></p>
                            <!-- <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="vhtmlText(i.text)"></p> -->
                            <!-- <div class="division" v-if="$i18n.locale=='ar'">
                                <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.1265 0.680164L6.0592 4.77808L6.73634 5.46032L10.8036 1.36241L10.1265 0.680164Z" fill="#B1BD52"/>
                                    <path d="M10.1302 3.40876L7.41504 6.14441L8.09218 6.82665L10.8074 4.091L10.1302 3.40876Z" fill="#B1BD52"/>
                                    <path d="M14.1926 4.7787L13.5155 5.46094L14.1926 6.14318L14.8698 5.46094L14.1926 4.7787Z" fill="#B1BD52"/>
                                    <path d="M11.4843 4.77402L8.77136 7.50745L9.4485 8.18969L12.1615 5.45626L11.4843 4.77402Z" fill="#B1BD52"/>
                                    <path d="M11.4855 7.5066L10.1268 8.87555L10.804 9.55779L12.1627 8.18885L11.4855 7.5066Z" fill="#B1BD52"/>
                                    <path d="M10.1303 3.41039L13.516 6.82159L14.1931 6.13935L10.8074 2.72815L10.1303 3.41039Z" fill="#B1BD52"/>
                                    <path d="M12.7116 3.28386L14.8713 5.4599L15.5485 4.77766L13.3887 2.60162L12.7116 3.28386Z" fill="#B1BD52"/>
                                    <path d="M10.1306 6.14035L12.162 8.18707L12.8391 7.50483L10.8077 5.4581L10.1306 6.14035Z" fill="#B1BD52"/>
                                    <path d="M6.06551 4.77829L8.09692 6.82501L8.77406 6.14277L6.74265 4.09604L6.06551 4.77829Z" fill="#B1BD52"/>
                                    <path d="M10.1302 0.682227L12.0355 2.60187L12.7127 1.91962L10.8074 -1.60074e-05L10.1302 0.682227Z" fill="#B1BD52"/>
                                    <path d="M1.31787 10.922L5.38513 15.02L6.06227 14.3377L1.99501 10.2398L1.31787 10.922Z" fill="#B1BD52"/>
                                    <path d="M4.02626 10.9169L6.74146 13.6525L7.4186 12.9703L4.7034 10.2346L4.02626 10.9169Z" fill="#B1BD52"/>
                                    <path d="M5.38695 6.82478L6.06409 7.50702L6.74122 6.82478L6.06409 6.14254L5.38695 6.82478Z" fill="#B1BD52"/>
                                    <path d="M5.38443 9.55051L8.09741 12.2839L8.77455 11.6017L6.06157 8.86827L5.38443 9.55051Z" fill="#B1BD52"/>
                                    <path d="M8.09406 9.55098L9.45276 10.9199L10.1299 10.2377L8.77119 8.86874L8.09406 9.55098Z" fill="#B1BD52"/>
                                    <path d="M4.02739 10.9174L7.41309 7.50623L6.73595 6.82398L3.35025 10.2352L4.02739 10.9174Z" fill="#B1BD52"/>
                                    <path d="M3.90481 8.31648L6.06458 6.14044L5.38744 5.4582L3.22768 7.63424L3.90481 8.31648Z" fill="#B1BD52"/>
                                    <path d="M6.73763 10.9159L8.76904 8.86914L8.09191 8.1869L6.06049 10.2336L6.73763 10.9159Z" fill="#B1BD52"/>
                                    <path d="M5.38525 15.0154L7.29053 13.0958L6.61339 12.4135L4.70811 14.3332L5.38525 15.0154Z" fill="#B1BD52"/>
                                    <path d="M1.32104 10.9203L3.22632 9.00067L2.54918 8.31843L0.643898 10.2381L1.32104 10.9203Z" fill="#B1BD52"/>
                                    <path d="M11.483 19.8005L15.5503 15.7026L14.8732 15.0203L10.8059 19.1182L11.483 19.8005Z" fill="#B1BD52"/>
                                    <path d="M11.4801 17.0709L14.1953 14.3353L13.5182 13.653L10.803 16.3887L11.4801 17.0709Z" fill="#B1BD52"/>
                                    <path d="M7.41979 15.7016L8.09692 15.0193L7.41979 14.3371L6.74265 15.0193L7.41979 15.7016Z" fill="#B1BD52"/>
                                    <path d="M10.125 15.7044L12.838 12.971L12.1609 12.2888L9.44789 15.0222L10.125 15.7044Z" fill="#B1BD52"/>
                                    <path d="M10.1259 12.9753L11.4846 11.6064L10.8075 10.9241L9.44878 12.2931L10.1259 12.9753Z" fill="#B1BD52"/>
                                    <path d="M11.4802 17.0687L8.09448 13.6575L7.41734 14.3397L10.803 17.7509L11.4802 17.0687Z" fill="#B1BD52"/>
                                    <path d="M8.89975 17.1965L6.73999 15.0204L6.06285 15.7027L8.22261 17.8787L8.89975 17.1965Z" fill="#B1BD52"/>
                                    <path d="M11.4783 14.3398L9.4469 12.2931L8.76976 12.9753L10.8012 15.0221L11.4783 14.3398Z" fill="#B1BD52"/>
                                    <path d="M15.5472 15.7023L13.642 13.7827L12.9648 14.4649L14.8701 16.3845L15.5472 15.7023Z" fill="#B1BD52"/>
                                    <path d="M11.4821 19.7978L9.57678 17.8781L8.89964 18.5604L10.8049 20.48L11.4821 19.7978Z" fill="#B1BD52"/>
                                    <path d="M20.2944 9.55848L16.2272 5.46057L15.55 6.14281L19.6173 10.2407L20.2944 9.55848Z" fill="#B1BD52"/>
                                    <path d="M17.5851 9.56445L14.8699 6.8288L14.1927 7.51104L16.9079 10.2467L17.5851 9.56445Z" fill="#B1BD52"/>
                                    <path d="M16.225 13.654L15.5479 12.9717L14.8707 13.654L15.5479 14.3362L16.225 13.654Z" fill="#B1BD52"/>
                                    <path d="M16.2295 10.9256L13.5165 8.1922L12.8393 8.87444L15.5523 11.6079L16.2295 10.9256Z" fill="#B1BD52"/>
                                    <path d="M13.5184 10.9259L12.1597 9.55695L11.4825 10.2392L12.8412 11.6081L13.5184 10.9259Z" fill="#B1BD52"/>
                                    <path d="M17.5839 9.56291L14.1982 12.9741L14.8754 13.6564L18.2611 10.2452L17.5839 9.56291Z" fill="#B1BD52"/>
                                    <path d="M17.7096 12.1625L15.5498 14.3386L16.2269 15.0208L18.3867 12.8448L17.7096 12.1625Z" fill="#B1BD52"/>
                                    <path d="M14.8758 9.56345L12.8444 11.6102L13.5215 12.2924L15.5529 10.2457L14.8758 9.56345Z" fill="#B1BD52"/>
                                    <path d="M16.2256 5.4658L14.3203 7.38544L14.9975 8.06768L16.9027 6.14804L16.2256 5.4658Z" fill="#B1BD52"/>
                                    <path d="M20.2918 9.56125L18.3865 11.4809L19.0636 12.1631L20.9689 10.2435L20.2918 9.56125Z" fill="#B1BD52"/>
                                    <path d="M11.4833 7.50884L10.8062 6.8266L9.45409 8.18885L10.1312 8.87109L11.4833 7.50884Z" fill="white"/>
                                    <path d="M12.1443 12.2759L11.4672 11.5936L10.1151 12.9559L10.7922 13.6381L12.1443 12.2759Z" fill="white"/>
                                    <path d="M9.45221 10.9194L8.09351 9.55048L7.41637 10.2327L8.77507 11.6017L9.45221 10.9194Z" fill="white"/>
                                    <path d="M14.1972 10.2437L12.8385 8.87476L12.1614 9.557L13.5201 10.9259L14.1972 10.2437Z" fill="white"/>
                                </svg>
                                </span>
                                <p class="tl">{{ i.cat_title }}</p>
                            </div>
                            <div class="date">
                                <span><img :src="$vuetify.theme.dark ? '/assets/img/date_big_white.svg' : '/assets/img/date_big_dark.svg'" /></span>
                                <p class="tl">{{ i.updated|FormatDate }}</p>
                            </div> -->
                        </router-link>
                        </swiper-slide>
                    </swiper>
                    <div :class="'sbtn rbtn d-none d-lg-flex d-xl-flex '+ $store.state.direction"></div>
                </div>
            </div>
        </div>






    <div class="news_con">





        



        <div class="picNews" v-if="interviewNews.length > 0">
            <div class="title">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <router-link :to="'/new/interviews'+'?lang='+$i18n.locale" class="tl">{{ $t("news_interviews") }}</router-link>
            </div>
            <div class="items">
            <v-container>
                    <v-layout row>
                    <v-flex sm6 xs12 md4 lg4 v-for="i in interviewNews" :key="i" style="padding:0 5px;">
                        <router-link class="fill-height item" :to="'/new/' + i.id + '?&lang=' +$i18n.locale">
                            <img  :src="'https://alkafeel.net/news/mcroped96/390/' + i.image" :alt="i.title" />
                            <div class="p_con tl" v-html="$options.filters.subStrArtTitle(i.title)"></div>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-container>
            </div>
        </div>



    </div>





        <news-search></news-search>
   </v-container>
</div>
</template>


<script>

import searchVue from './search.vue';
export default {
    data() {
        return {
            isPlaying: false,
            impoNews : [],
            reportNews : [],
            interviewNews : [],
            latestNews : [],
            picNews:[],
            videoNews:[],
            impoNews_skeleton : true,
            lastNews_skeleton : true,
            swiperOptions: {
                slidesPerView: 'auto',
                   autoplay: {
                        delay: 10000,
                        disableOnInteraction: true,
                    },
                spaceBetween: 30,
                freeMode: true,
                keyboard: true,
                // autoplay: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                
            },
            swiperOptions2: {
                slidesPerView: "auto",
                spaceBetween:40,
                loop:true,
                freeMode: true,
                navigation: {
                    nextEl: ".rbtn2",
                    prevEl: ".lbtn2",
                },
                keyboard: true,
            },
            swiperOptions3: {
                slidesPerView: 'auto',
                autoplay: {
                        delay: 19400,
                        disableOnInteraction: true,
                    },
                spaceBetween: 20,
                freeMode: true,
                keyboard: true,
                loop: true,
            
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: ".rbtn",
                    prevEl: ".lbtn",
                },
            },



            
        }
    },
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper}
    },
    mounted() {
        document.title =this.$t("news"); 
    }, 
    created () {
        this.getlatestNews();
        this.getpicNews();
        this.getvideoNews();
        this.grtimpoNews ();
        this.getreportNews ();
        this.getinterviewNews ();
    },
    components: {
        'news-search': searchVue
    },
    methods:{
        playVideo(event) {
            const video = event.target;
            video.play();
            this.isPlaying = true; // Update state to playing
        },
        pauseVideo(event) {
            const video = event.target;
            video.pause();
            this.isPlaying = false; // Update state to paused
        },
        togglePlay(event) {
            const video = event.target.closest('.video-wrapper').querySelector('video');
            if (video.paused) {
            video.play();
            this.isPlaying = true; // Update state to playing
            } else {
            video.pause();
            this.isPlaying = false; // Update state to paused
            }
        },
        updatePlayIcon(event) {
            const video = event.target;
            this.isPlaying = !video.paused; // Sync state with video's playback state
        },
        TimeSince: function (date) {
            date =date*1000;
            var seconds = Math.floor((new Date() - date) / 1000);
            var interval = seconds / 31536000;
            if (this.$i18n.locale === 'tr' || this.$i18n.locale === 'pr') {
                if (interval > 1) {return Math.floor(interval) +' '+ this.$t("time_year") +' '+ this.$t("time_before");}
                interval = seconds / 2592000;
                if (interval > 1) {return Math.floor(interval) +' '+ this.$t("one_month") +' '+ this.$t("time_before");}
                interval = seconds / 86400;
                if (interval > 1) {return Math.floor(interval) +' '+ this.$t("one_day") +' '+ this.$t("time_before");}
                interval = seconds / 3600;
                if (interval > 1) {return Math.floor(interval) +' '+ this.$t("time_hour") +' '+ this.$t("time_before");}
                interval = seconds / 60;
                if (interval > 1) {return Math.floor(interval) +' '+ this.$t("time_min") +' '+ this.$t("time_before");}
                return Math.floor(seconds) +' '+ this.$t("time_sec") +' '+ this.$t("time_before");
            } else {
                if (interval > 1) {return this.$t("time_before") +' '+ Math.floor(interval) +' '+ this.$t("time_year");}
                interval = seconds / 2592000;
                if (interval > 1) {return this.$t("time_before") +' '+ Math.floor(interval) +' '+ this.$t("one_month");}
                interval = seconds / 86400;
                if (interval > 1) {return this.$t("time_before") +' '+ Math.floor(interval) +' '+ this.$t("one_day");}
                interval = seconds / 3600;
                if (interval > 1) {return this.$t("time_before") +' '+ Math.floor(interval) +' '+ this.$t("time_hour");}
                interval = seconds / 60;
                if (interval > 1) {return this.$t("time_before") +' '+ Math.floor(interval) +' '+ this.$t("time_min");}
                return this.$t("time_before") +' '+ Math.floor(seconds) +' '+ this.$t("time_sec");
            }
            
        },
        grtimpoNews () {
            this.$axios.get("articles/GetImportant/4", {
            })
            .then(res => {
                this.impoNews = res.data;
                setTimeout(() => this.impoNews_skeleton = false, 500);
                //console.log(res.data);
            })
            .catch(() => {
            });
        },


        getreportNews () {
            this.$axios.get("articles/Getreportnews/4?page=1", {
            })
            .then(res => {
                this.reportNews = res.data.data;
            })
        },
        getinterviewNews () {
            this.$axios.get("articles/Getinterviewnews/6?page=1", {
            })
            .then(res => {
                this.interviewNews = res.data.data;
            })
        },

        getlatestNews () {
            this.$axios.get("articles/GetByLimit/7", {
            })
            .then(res => {
                this.latestNews = res.data;
                setTimeout(() => this.lastNews_skeleton = false, 500);
            })
        },
        getpicNews() {
            this.$axios.get("articles/GetInfograph/3/0?page=1", {
            })
            .then(res => {
                this.picNews = res.data.data;
            })
        },
        getvideoNews() {
            this.$axios.get("articles/GetVideosNews/6?page=1", {
            })
            .then(res => {
                this.videoNews = res.data.data;
            })
        },
        vhtmlText (i) {
            if (i != null) {return this.stripHTML(i.replace(/\[\/?[^\]]*\]/g, '').substring(0, 110) + '...')}
        },
        removebbcode (x) {
            if (x) {return this.stripHTML(x.replace(/\[\/?[^\]]*\]/g, ''))}
        },
        stripHTML (value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        }
    },
    filters: {
        subStrvideoNewsTitle: function (string) {if (string != null) {return string.substring(0, 65) + '...';}},
        subStrimpoNewsTitle: function (string) {if (string != null) {return string.substring(0, 80) + '...';}},
        subStrArtTitle: function (string) {if (string != null) {return string.substring(0, 85) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },
    },
}
</script>

<style>

.full-height-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}
.video-container {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}
.full-height-img:hover {
    transform: scale(1.05);
}

.video-wrapper {
  position: relative;
  display: inline-block;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.play-btn:hover {
  background: rgba(0, 0, 0, 0.9);
}

.play-icon {
  margin-left: 4px;
}

.inf .v-carousel {
    overflow:visible
}
.inf .v-carousel__controls .v-icon {
    font-size: 8px;
}
.inf .v-carousel__controls {
    position: relative;
    background: transparent;
}
.inf .mdi-circle::before {
    content: ""!important;
}
.inf .v-carousel__controls__item.v-btn.v-btn--icon {
    background-color: #000; 
    width: 10px;
    height: 10px;
}
  
.inf .v-carousel__controls__item.v-btn.v-btn--icon.v-btn--active {
    background-color: #cac2c2; 
}

.inf .v-carousel__controls__item.v-btn.v-btn--icon:hover {
    background-color: black; 
}



</style>

<style lang="scss" scoped>
.inf_item {
    display: block;
    height: 100%;
}
.inf {
    margin: -12px !important;
}

.infograph {
    padding: 0 !important;
}

.mx-h {
    max-height: 400px;
}

.swiper-slide {
    background: transparent !important;
}
</style>